import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import config from "../../config";
import { Link } from "react-router-dom";

interface Props {
  incident: any;
  videos: any[];
  convertToUtcOffset: any;
}

interface State {}

export default class Download extends React.Component<Props, State> {
  emailInput: HTMLInputElement;
  constructor(props: Props) {
    super(props);
  }

  // downloadPdf = () => {
  //   window.print()
  // }

  render() {
    var { incident, videos, convertToUtcOffset } = this.props;
    // var createdAt = moment(incident.createdAt).format("LLL");
    var createdAt = convertToUtcOffset(incident.createdAt, incident.timezone_utc_offset, "LLL")
    // var updatedAt = moment(incident.updatedAt).format("LLL");
    var updatedAt = convertToUtcOffset(incident.updatedAt, incident.timezone_utc_offset, "LLL")
    // if (incident.timezone_utc_offset != "dynamic") {
    //   createdAt = convertToUtcOffset(incident.createdAt, incident.timezone_utc_offset, "LLL")
    //   updatedAt = convertToUtcOffset(incident.updatedAt, incident.timezone_utc_offset, "LLL")
    // }
    return (
      <Card>
        <Card.Header>
          <i className="fa fa-fw fa-download"></i> Download Incident Report
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-12">
              <div className="hidden-sm hidden-xs mt-1 mb-1">
                <h5>
                  <i className="fa fa-fw fa-link"></i>{" "}
                  <strong>
                    https://{config.domain}/incidents/{incident.id}
                  </strong>
                </h5>
              </div>

              <h4>
                <strong>Incident Details</strong>
              </h4>
              <table>
                <tbody>
                  <tr>
                    <td>Created At:&nbsp;</td>
                    <td>{createdAt}</td>
                  </tr>
                  <tr>
                    <td>Last Updated At:&nbsp;</td>
                    <td>{updatedAt}</td>
                  </tr>
                  <tr>
                    <td>Location:&nbsp;</td>
                    <td>{incident.locationName}</td>
                  </tr>
                  <tr>
                    <td>Address:&nbsp;</td>
                    <td>{incident.locationAddress}</td>
                  </tr>
                  <tr>
                    <td>Monitoring Company:&nbsp;</td>
                    <td>{incident.providerName}</td>
                  </tr>
                  <tr>
                    <td>Phone Number:&nbsp;</td>
                    <td>{incident.providerPhone}</td>
                  </tr>
                </tbody>
              </table>

              <br />

              <h4>
                <strong>Download Media</strong>
              </h4>
                {/* <div>
                  <a style={{color: "blue", cursor: "pointer"}} className="nav-link" onClick={this.downloadPdf}>Download Page as pdf</a>
                </div> */}
                {/* <div>
                  <Link to="/terms" className="nav-link">Terms of Service</Link>
                </div> */}
              <ul className="list-unstyled">
                {videos.map((video, i) => {
                  if (video.videoURL !== "https://s3.amazonaws.com//") {
                    //videoURL was not built with bucket and db url
                    return (
                      <li key={i}>
                        <a href={video.videoURL} target="_blank">
                          <i className="fa fa-fw fa-download"></i> Download
                          Media #{i + 1}
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}
