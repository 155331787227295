import 'es6-shim';
import 'whatwg-fetch';

import "json.date-extensions";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, withRouter } from "react-router-dom";
import SmartBanner from 'react-smartbanner';
import App from "./components/app";
import config from "./config";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import 'react-smartbanner/dist/main.css';
import "./assets/scss/app.scss";

window.onload = function () {
    let installUrl = {
        ios: config.installUrl,
        android: config.installUrl
    }

    let AppRoute = withRouter(App)

    ReactDOM.render((
        <>
            <BrowserRouter>
                <AppRoute />
            </BrowserRouter>
            {/* <SmartBanner title="Incident Code" position="bottom" button="Open" url={installUrl} daysHidden={0} daysReminder={0} /> */}
        </>
    ), document.getElementById("app"))

}