import { Incident, IncidentVideo } from "incident-code-core";
import React from "react";
import { GoogleMap } from "react-google-maps";
import { VideoMarker } from "../../types";
import MapView from "../map";

interface Props {
  initialPosition?: VideoMarker;
  video: IncidentVideo;
  markers: VideoMarker[];
  locations: [];
}

interface State {}

export default class IncidentMap extends React.Component<Props, State> {
  map: GoogleMap;

  constructor(props: Props) {
    super(props);
  }

  onMapLoaded = (map: GoogleMap) => {
    if (map) {
      this.map = map;
      this.centerMap();
    }
  };

  centerMap() {
    var { video, initialPosition } = this.props;
    var locations : any = this.props.locations
    const last  = locations && locations.length ? locations[locations.length - 1].location : initialPosition.position;

    if (video && video.location) {
      this.map.panTo(
        new google.maps.LatLng(
          video.location.coordinates[1],
          video.location.coordinates[0]
        )
      );
    } else if (initialPosition && initialPosition.position) {
      this.map.panTo(
        new google.maps.LatLng(
          last.lat as number,
          last.lng as number
        )
      );
    }
  }

  render() {
    return (
      <MapView
        onMapLoaded={this.onMapLoaded}
        markers={this.props.markers}
        initialPosition={this.props.initialPosition}
        locations={this.props.locations}
      />
    );
  }
}
