import { isPhoneNumber } from "incident-code-core";
import React from "react";
import { Col, FormControl, Jumbotron, Row } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import config from "../config";
import { ppApiService } from "../services/pp-api-service";
import { ppicApiService } from "../services/ppic-api-service";
import { logEvent } from "../utils/ga";

interface State {
  code?: string;
  codeError?: any;
  phone?: string;
  phoneError?: any;
}

export default class Entry extends React.Component<RouteComponentProps, State> {
  codeInput: HTMLInputElement;
  phoneInput: HTMLInputElement;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      code: "",
      phone: "",
    };
  }

  componentDidMount() {
    // when the component renders, focus on the text input

    let phone = location.pathname.replace(/^\//, "");
    if (isPhoneNumber(phone)) {
      this.setState({ phone });
      this.lookupPhone(phone);
    } else {
      this.codeInput.focus();
    }
  }

  lookupCode(code: string) {
    this.setState({
      codeError: "Validating code...",
    });

    ppApiService
      .lookup(code)
      .then((result) => {
        logEvent({
          category: "entry",
          action: "lookup-success",
          label: code,
        });

        this.setState({ codeError: null });

        if (result.type != "2") {
          this.props.history.push("/incidents/" + result.id);
        } else {
          ppicApiService
            .lookup(code)
            .then((wallResult) => {
              logEvent({
                category: "entry",
                action: "lookup-success",
                label: code,
              });

              this.setState({ codeError: null });
              this.props.history.push("/incident/" + wallResult.id, wallResult);
            })
            .catch((error) => {
              logEvent({
                category: "entry",
                action: "lookup-failure",
                label: code,
              });

              this.setState({
                codeError: `Incident with ${code} could not be found`,
              });
            });
        }
      })
      .catch((error) => {
        logEvent({
          category: "entry",
          action: "lookup-failure",
          label: code,
        });

        this.setState({ codeError: error.message || error.error });
      });
  }

  lookupPhone(phone: string) {
    this.setState({
      phoneError: "Validating phone...",
    });

    ppicApiService
      .lookup(phone)
      .then((result) => {
        logEvent({
          category: "entry",
          action: "lookup-success",
          label: phone,
        });

        this.setState({ phoneError: null });
        this.props.history.push("/incident/" + result.id, result);
      })
      .catch(() => {
        logEvent({
          category: "entry",
          action: "lookup-failure",
          label: phone,
        });

        this.setState({
          phoneError: `Incident with ${phone} could not be found`,
        });
      });
  }

  render() {
    const { code, phone, codeError, phoneError } = this.state;
    return (
      <Row>
        <Col xs="12" md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <Jumbotron>
            <h1>Enter Incident Code</h1>

            <FormControl
              ref={(r: any) => (this.codeInput = r)}
              type="text"
              className="entry-box"
              placeholder="******"
              maxLength={config.codeMaxLength}
              value={code}
              onChange={this.handleCodeChange}
              isInvalid={codeError}
            />

            {codeError && (
              <div className="alert alert-danger mt-2" role="alert">
                {codeError}
              </div>
            )}

            <div></div>
            <button
              type="button"
              className={"btn btn-success"}
              onClick={this.handleSearch}
            >
              Search{" "}
            </button>

            <p className="mt-2">
              By using this site you agree to our{" "}
              <Link to="/terms">
                <strong>Terms of Service</strong>
              </Link>
            </p>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  private handleCodeChange = (event: any) => {
    var code = event.target.value;
    //var isComplete = (code.length == config.codeMaxLength)
    this.setState({
      code: code,
      codeError: null,
    });

    // if (isComplete) {
    // 	this.lookupCode(code)
    // }
  };

  private handleSearch = (event: any) => {
    this.lookupCode(this.state.code);
  };

  private handlePhoneChange = (event: any) => {
    var phone: string = event.target.value;
    if (phone.length > 9) {
      var isComplete = isPhoneNumber(phone);

      this.setState({
        phone: phone,
        phoneError: isComplete ? null : "incorrect phone number",
      });

      if (isComplete) {
        this.lookupPhone(phone);
      }
    } else {
      this.setState({ phone });
    }
  };
}
