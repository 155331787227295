import { Incident, IncidentVideo } from "incident-code-core"
import moment from "moment"
import React from "react"
import { Card } from "react-bootstrap"
import config from "../../config"
import { ppicApiService } from "../../services/ppic-api-service"

interface Props {
  incident: Incident
  videos: IncidentVideo[]
}

interface State {}

export default class Download extends React.Component<Props, State> {
  emailInput: HTMLInputElement
  constructor(props: Props) {
    super(props)
  }

  render() {
    var { incident, videos } = this.props
    var category = ppicApiService.config.getIncidentCategory(incident.category).display
    var createdAt = moment(incident.createdAt).format("LLL")
    var updatedAt = moment(incident.updatedAt).format("LLL")

    return (
      <Card className="mt-4 mb-4">
        <Card.Header>
          <i className="fa fa-fw fa-download"></i> Download Incident Report
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-12">
              <div className="hidden-sm hidden-xs mt-1 mb-1">
                <h5>
                  <i className="fa fa-fw fa-link"></i>{" "}
                  <strong>
                    https://{config.domain}/incident/{incident.id}
                  </strong>
                </h5>
              </div>

              <h4>
                <strong>Incident Details</strong>
              </h4>
              <table>
                <tbody>
                  <tr>
                    <td>Category:&nbsp;</td>
                    <td>{category}</td>
                  </tr>
                  <tr>
                    <td>Created At:&nbsp;</td>
                    <td>{createdAt}</td>
                  </tr>
                  <tr>
                    <td>Last Updated At:&nbsp;</td>
                    <td>{updatedAt}</td>
                  </tr>
                  <tr>
                    <td>Address:&nbsp;</td>
                    <td>{incident.address}</td>
                  </tr>
                </tbody>
              </table>

              <br />

              <h4>
                <strong>Download Videos</strong>
              </h4>
              <ul className="list-unstyled">
                {videos.map((video, i) => {
                  return (
                    <li key={i}>
                      <a href={video.url} target="_blank">
                        <i className="fa fa-fw fa-download"></i> Download Video #{i + 1}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </Card.Body>
      </Card>
    )
  }
}
