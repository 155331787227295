import ga from "react-ga"
import config from "../config"

export var logPageView: (location: { pathname: string }) => void
export var logEvent: (event: ga.EventArgs) => void

if (config.ga) {
    ga.initialize(config.ga)

    logPageView = function (location) {
        // console.debug("Route change", location)
        ga.pageview(location.pathname)
    }

    logEvent = function (args) {
        ga.event(args)
    }
} else {
    logPageView = function (location) {
        // console.debug("Route change", location)
    }

    logEvent = function (args) {
    }
}

