import { urlUtils } from "incident-code-core";

export abstract class ApiService {
  protected abstract baseUrl: string;
  protected basePaths: string[];

  protected get<T = any, Q = any>(paths?: string[], query?: Q): Promise<T> {
    return this.request(paths, "GET", query, null);
  }

  protected post<T = any, B = T>(
    paths?: string[],
    body?: Partial<B>
  ): Promise<T> {
    return this.request(paths, "POST", null, body);
  }

  protected patch<T = any, B = T>(
    paths?: string[],
    body?: Partial<B>
  ): Promise<T> {
    return this.request(paths, "PATCH", null, body);
  }

  protected delete<T = any, Q = any>(paths?: string[], query?: Q): Promise<T> {
    return this.request(paths, "DELETE", query);
  }

  protected addHeaders(request: Request, contentType = "application/json") {
    request.headers.set("Content-Type", contentType);
  }

  protected generateUrl(paths: string[], query?: any): string {
    let p = [this.baseUrl];

    if (this.basePaths && this.basePaths.length) p.push(...this.basePaths);

    if (paths && paths.length) p.push(...paths);

    return urlUtils.generate(p, query);
  }

  protected errorHandler = (error: any | Response) => {
    // TODO: log error
    throw error;
  };

  private request<T = any>(
    paths: string[],
    method: string,
    query?: any,
    body?: any,
    flatting?: boolean
  ): Promise<T> {
    var url = this.generateUrl(paths, query);
    var options: RequestInit = {
      method: method,
      redirect: "follow",
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    var request = new Request(url, options);

    this.addHeaders(request);

    return fetch(request)
      .then(async (response) => {
        var result: any = await response.text();
        var contentType = response.headers.get("content-type") || "";
        if (contentType.indexOf("json") > -1) {
          result = JSON.parseWithDate(result);
        }

        if (response.ok) {
          return result;
        } else {
          this.errorHandler(result);
        }
      })
      .catch(this.errorHandler);
  }
}
