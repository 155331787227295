import React from "react"

export default function Terms() {
  return (
    <>
      <h2>INCIDENTCODE.COM TERMS AND CONDITIONS OF USE</h2>

      <p>This Agreement and the Terms and Conditions described herein shall be binding upon all users of incidentcode.com and Police Priority&trade; Service developed for use on the Site. If you are a licensee and subject to the Police Priority Customer Agreement, that Customer Agreement and the terms therein shall be supplemental the Terms and Conditions described herein.</p>

      <p>PLEASE READ THE TERMS OF THIS AGREEMENT CAREFULLY BEFORE ACCESSING OR USING THE SITE OR THE SERVICE.&nbsp; BY ACCESSING OR USING THE SITE OR THE SERVICE, YOU AGREE TO BE LEGALLY BOUND BY THIS AGREEMENT.&nbsp; PLEASE DO NOT USE THE SITE OR THE SERVICE IF YOU DO NOT AGREE WITH THIS AGREEMENT.&nbsp;&nbsp;</p>

      <p>IF YOU DO NOT ACCEPT THESE TERMS YOU WILL NOT BE CONSIDERED AN AUTHORIZED USER OF THE SITE.&nbsp; BY PROCEEDING TO UTILIZE THE SITE AND TECHNOLOGIES ASSOCIATED WITH THE SITE, IT WILL MEAN THAT YOU AGREE TO THIS AGREEMENT WHICH WILL BE LEGALLY BINDING ON YOU.&nbsp;</p>

      <p>1. <strong>DEFINITIONS</strong></p>

      <ol>
        <li><strong>Authorized User</strong>: An individual who has read these Terms and Conditions, agreed to the Terms and Conditions, intend to and in fact do use the Site for its intended and lawful purpose.</li>
        <li><strong>Agreement</strong>: the entirety of this document, as it may be updated from time to time by the Provider.</li>
        <li><strong>Terms and Conditions</strong>: The usage requirements described in the Agreement.</li>
        <li><strong>The Provider</strong>: Police Priority, LLC, a South Carolina Limited Liability Company, Police Priority, Inc., a North Carolina Corporation, and affiliated entities.</li>
        <li><strong>The Site</strong>: incidentcode.com</li>
        <li><strong>The Service</strong>: Police Priority incident response technology utilized on the Site, as described more thoroughly in Section 9.</li>
      </ol>

      <p><strong>2.&nbsp;&nbsp; DISCLAIMERS</strong></p>

      <p>YOU UNDERSTAND AND AGREE THAT THE SITE AND THE CONTENT AND FUNCTIONALITY OF THE SERVICE, INCLUDING PROGRAMMING, ARE PROVIDED &quot;AS IS&quot; AND &quot;AS AVAILABLE.&quot;&nbsp; THE PROVIDER AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY, EITHER EXPRESS OR IMPLIED, REGARDING THE SERVICE, THE RELIABILITY, PREDICTIVE VALUE, COMPLETENESS, TIMELINESS, RELIABILITY, OR ACCURACY OF THE INFORMATION CONTAINED WITHIN THE SERVICE, OUR TRANSMISSION, YOUR RECEPTION, OR THAT YOUR ACCESS TO OR YOUR USE OF THE SERVICE OR THE SITE WILL BE UNINTERRUPTED, ERROR FREE OR TIMELY WITH ALL UPDATES.&nbsp; ALL SUCH WARRANTIES (INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT) ARE HEREBY DISCLAIMED.&nbsp;</p>

      <p>YOU ACKNOWLEDGE AND AGREE THAT UNDER NO CIRCUMSTANCES SHOULD A USER OF THE SERVICE MAKE DECISIONS BASED SOLELY OR IN PART ON INFORMATION CONTAINED WITHIN THE SERVICE.&nbsp; NEITHER THE PROVIDER, NOR ITS AFFILIATES ASSUME ANY RESPONSIBILITY FOR ACCIDENTS, DAMAGES, BODILY HARM OR OTHER LOSSES RESULTING FROM OR ASSOCIATED WITH USE AND/OR MISUSE OF THE SERVICE. &nbsp;THE SERVICE IS NOT INTENDED TO BE USED WHILE OPERATING A VEHICLE. WHETHER YOU ARE A LICENSEE, THIRD PARTY, OR LAW ENFORCEMENT OFFICER, IT IS YOUR RESPONSIBILITY TO USE THE SERVICE IN A SAFE, SECURE, REASONABLE, AND RESPONSIBLE MANNER. THE SERVICE IS NOT TO BE USED IN ANY SITUATION THAT MAY BE CONSIDERED DANGEROUS OR MAY INVOLVE CRIMINAL ACTIVITY, SUSPECTED CRIMINAL ACTIVITY, OR ANYTHING OF THE SORT. IT IS YOUR RESPONSIBILITY TO ABIDE BY ALL APPLICABLE STATE SPECIFIC LAWS INCLUDING TRAFFIC AND SAFETY LAWS.</p>

      <p><strong>3.&nbsp; LIMITATIONS OF LIABILITY</strong></p>

      <p>&nbsp;IN NO EVENT ARE THE PROVIDER OR ITS AFFILIATES LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, LOST PROFITS, OR LOSSES RELATING TO THE USE, LOSS OF USE OR DATA, USE OF THE SERVICE, OR FROM ANY CONTENT POSTED ON THE SITE, WHETHER BASED ON NEGLIGENCE OR OTHERWISE, AND WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY THEREOF, WHETHER ARISING OUT OF BREACH OF THIS AGREEMENT, TORT OR ANY OTHER CAUSE OF ACTION RELATING TO THE PERFORMANCE OR NON-PERFORMANCE OF THIS AGREEMENT.&nbsp;</p>

      <p>YOU AGREE THAT THIS LIMITATION OF LIABILITY REPRESENTS A REASONABLE ALLOCATION OF RISK.&nbsp; THIS ALLOCATION OF RISK AND THE DISCLAIMER OF WARRANTIES HEREIN ARE REFLECTED IN OUR PRODUCT OFFERINGS AND ARE A FUNDAMENTAL ELEMENT OF THIS AGREEMENT.&nbsp; YOU MAY HAVE GREATER RIGHTS THAN DESCRIBED ABOVE UNDER YOUR STATE&#39;S LAWS.&nbsp;&nbsp;&nbsp;</p>

      <p><strong>4.&nbsp; YOUR RISK</strong></p>

      <p>YOU AGREE THAT YOUR ACCESS TO AND USE OF, OR INABILITY TO ACCESS OR USE THE SERVICE OR THE SITE IS AT YOUR SOLE RISK.&nbsp; YOU WILL NOT HOLD US, OUR PROGRAMMING OR DATA SUPPLIERS, SERVICE PROVIDERS, MARKETING/DISTRIBUTION, SERVICE OR INTERNET SUPPLIERS, OR HARDWARE OR SERVICE MANUFACTURERS, OR SUPPLIERS, OR OUR CONTRACTORS OR LICENSORS, AS APPLICABLE, RESPONSIBLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, OR LOST PROFITS RESULTING FROM YOUR ACCESS TO OR USE OF, OR INTERRUPTIONS IN THE TRANSMISSION OR RECEPTION OF THE SERVICE OR SITE, INCLUDING ANY DAMAGE TO ANY OF YOUR COMPUTERS OR DATA, AND/OR ANY RECEIVER.&nbsp; NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY ANY PERSON SHALL CREATE A WARRANTY OR GUARANTEE IN ANY WAY WHATSOEVER RELATING TO THE SERVICE OR SITE.&nbsp;&nbsp;</p>

      <p><strong>5.&nbsp; STATE LAW</strong></p>

      <p>&nbsp;SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF CERTAIN IMPLIED WARRANTIES OR THE LIMITATION OF CERTAIN DAMAGES, SO SOME OF THE ABOVE DISCLAIMERS, WAIVERS AND LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.&nbsp;&nbsp;</p>

      <p><strong>6.&nbsp; MISCELLANEOUS</strong></p>

      <p>UNLESS LIMITED OR MODIFIED BY APPLICABLE LAW, THE FOREGOING DISCLAIMERS, WAIVERS AND LIMITATIONS SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED, EVEN IF ANY REMEDY FAILS ITS ESSENTIAL PURPOSE.&nbsp; OUR LICENSORS AND CONTRACTORS ARE INTENDED THIRD-PARTY BENEFICIARIES OF THESE DISCLAIMERS.&nbsp;</p>

      <p><strong>7.&nbsp; INDEMNIFICATION&nbsp; </strong></p>

      <p>EXCEPT FOR WILLFUL MISCONDUCT ON THE PART OF THE PROVIDER OR &nbsp;ITS AFFILIATES YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS THE PROVIDER, ITS AFFILIATES, SUBSIDIARIES, SHAREHOLDERS, OFFICERS, AGENTS, EMPLOYEES, LICENSORS, LICENSEES AND SERVICE PROVIDERS, (&ldquo;INDEMNIFIED PARTIES&rdquo;) FROM ANY AND ALL CLAIMS, LIABILITY AND EXPENSES (INCLUDING REASONABLE ATTORNEYS&#39; FEES AND EXPENSES), WHETHER IN TORT, CONTRACT OR OTHERWISE, RELATING TO OR ARISING OUT OF YOUR USE OF THE SERVICE AND ANY BREACH OF THIS AGREEMENT, APPLICABLE LAW OR ANY RIGHT OF THE INDEMNIFIED PARTIES OR ANY THIRD PARTY.&nbsp;</p>

      <p><strong>8.&nbsp; RESOLVING DISPUTES</strong></p>

      <p>PLEASE READ THIS PROVISION OF THIS SECTION CAREFULLY.&nbsp; IT PROVIDES THAT ANY DISPUTE MAY BE RESOLVED BY BINDING ARBITRATION.&nbsp; BY AGREEING TO ARBITRATION, YOU ARE HEREBY WAIVING THE RIGHT TO GO TO COURT, INCLUDING THE RIGHT TO A JURY.&nbsp; IN ARBITRATION, A DISPUTE IS RESOLVED BY AN ARBITRATOR, OR A PANEL OF ARBITRATORS, INSTEAD OF A JUDGE OR JURY.&nbsp; THE PARTIES UNDERSTAND THAT THEY WOULD HAVE HAD A RIGHT OR OPPORTUNITY TO LITIGATE DISPUTES THROUGH A COURT AND TO HAVE A JUDGE OR JURY DECIDE THEIR CASE, BUT THEY CHOOSE (BY THEIR ACCEPTANCE OF THIS AGREEMENT, IN ACCESSING OR USING THE SERVICE OR THE SITE) TO HAVE ANY DISPUTES RESOLVED THROUGH ARBITRATION.</p>

      <p>This arbitration agreement is made pursuant to a transaction involving interstate commerce and shall be governed by the Federal Arbitration Act (&ldquo;FAA&rdquo;), and not by any state law concerning arbitration. Notwithstanding the foregoing, any dispute involving a violation of the Communications Act of 1934, 47 U.S.C. &sect;605, the Electronic Communications Privacy Act, 18 U.S.C. &sect;&sect;2510-2521, or a violation of our intellectual property rights may be decided only by a court of competent jurisdiction.&nbsp;&nbsp;</p>

      <p>If either party elects to resolve a claim by arbitration, that Claim shall be arbitrated on an individual basis.&nbsp; There shall be no right or authority for any claims to be arbitrated on a class action basis or on bases involving Claims brought in a purported representative capacity on behalf of the general public, other Authorized Users, or other persons similarly situated.&nbsp; No Claim submitted to arbitration is heard by a jury or may be brought as a private attorney general.&nbsp; You do not have the right to act as a class representative or participate as a member of a class of claimants with respect to any Claim submitted to arbitration (&ldquo;Class Action Waiver&rdquo;).&nbsp; The parties to this Agreement acknowledge that the Class Action Waiver is material and essential to the arbitration of any disputes between the parties and is nonseverable from this agreement to arbitrate Claims. &#39;Claim&#39; does not include any challenge to the validity and effect of the Class Action Waiver, which must be decided by a court.&nbsp; The parties acknowledge and agree that under no circumstances will a class action be arbitrated.&nbsp; If any portion of this arbitration agreement or the Class Action Waiver is limited, voided or cannot be enforced, then the parties&#39; agreement to arbitrate (except for this sentence) shall be null and void with respect to such proceeding, subject to the right to appeal the limitation or invalidation of the Class Action Waiver, that portion will be severed, and the rest of the arbitration agreement will continue to apply.&nbsp; If this entire agreement to arbitrate shall be null and void, then the parties agree that any actions shall be brought in the State or Federal courts of Charlotte, North Carolina. &nbsp;&nbsp;</p>

      <p>In the arbitration proceeding, the arbitrator must follow applicable law, and any award may be challenged, as set forth in the FAA.&nbsp; Any court with jurisdiction may enter judgment upon the arbitrator&rsquo;s award.&nbsp; The arbitrator&#39;s decision is final and binding on all parties and may be enforced in any federal or state court with jurisdiction.</p>

      <p><strong>9.&nbsp; PROVIDER SERVICE</strong></p>

      <p>The Provider offers middleware software that enables central monitoring stations to broadcast real-time video, audio, and location data of an incident for the purpose of elevating the dissemination of relevant information. The relevant information is sent directly to the responding officer via laptop and/or smartphone. With this technology, first responders will now have access to the same information that the central station has in real- time. This description is not intended to be an exhaustive or exclusive summary of the Service. Provider reserves the right to alter the Software and its capabilities in any way Provider deems fit.</p>

      <p><strong>10. TRAINING</strong></p>

      <p>The Provider intends to ensure all Authorized Users are thoroughly trained in their use of the Services. Thus, in the event an Authorized User desires training of any kind in use of the Services, please contact the Provider and training will be scheduled at a time and place of mutual convenience.</p>

      <p><strong>11. RELATIONSHIP OF THE PARTIES</strong></p>

      <p>The relationship between the parties hereto is solely that of licensee and licensor, and nothing herein shall be deemed or construed to create any franchise, joint venture, partnerships, or relationship other than Licensee and Licensor. In all dealings with third parties, including without limitation, employers, suppliers, and customers, you shall disclose in an appropriate manner, that Provider/licensor is an independent entity who has contracted with you for the limited purposes described herein. Nothing in this Agreement is intended by the parties to create a fiduciary relationship between them, nor to constitute one party as an agent, legal representative, subsidiary, franchise, joint venture, partner, employee, or agent of another party for any purpose whatsoever.</p>

      <p><strong>12. FULL AGREEMENT</strong></p>

      <p>&nbsp;This Agreement constitutes the entire agreement between us concerning your access to and use of the Service or Site and may be modified by the unilateral amendment of this Agreement and the posting by us of such amended version.&nbsp; If any provision is declared by a competent authority to be invalid, that provision will be deleted or modified to the extent necessary, and the rest of this Agreement will remain enforceable.&nbsp; Any specific terms that expressly or by their nature survive termination shall continue thereafter until fully performed.&nbsp; A waiver of any terms or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.</p>
    </>
  );
}
