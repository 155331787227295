import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import ReactDOM from "react-dom";
import { VideoMarker } from "../../types";
import IncidentMap from "./incident-map";
import { Incident, IncidentVideo, AppUser } from "incident-code-core";
import { ppicApiService } from "../../services/ppic-api-service";

interface Props {
  incident: Incident;
  videos: IncidentMedia[];
  locations: [];
  isToken?: boolean;
  convertToUtcOffset?: Function;
}

interface State {
  activeVideoIndex?: number;
  users?: any;
}

interface IncidentMedia extends IncidentVideo {
  image?: boolean;
}

export default class VideoContainer extends React.Component<Props, State> {
  deviceMarkerIcons = {
    latest:
      "https://s3.amazonaws.com/assets.incidentcode.com/marker-video-latest.png",
    active:
      "https://s3.amazonaws.com/assets.incidentcode.com/marker-video-active.png",
    default:
      "https://s3.amazonaws.com/assets.incidentcode.com/marker-video-default.png",
    user:
      "https://s3.amazonaws.com/assets.incidentcode.com/new-map-image.png",
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      activeVideoIndex: 0,
    };
  }

  handleMarkerClick = (index: number) => {
    this.setState({
      activeVideoIndex: index,
    });
  };

  handlePageChange(direction: string) {
    var len = this.props.videos.length;
    var index = this.state.activeVideoIndex;

    if (direction == "next") {
      index = index + 1 >= len ? 0 : index + 1; // if we are the end, loop back to beginning
    } else {
      index = index == 0 ? len - 1 : index - 1;
    }

    this.setState({
      activeVideoIndex: index,
    });
  }

  /**
   * renders the video container and pagination
   */
  renderVideo() {
    const { activeVideoIndex } = this.state;
    const { videos } = this.props
    const activeVideo = videos[activeVideoIndex]


    if (!activeVideo && !videos.length) {
      return (
        <div className="thumbnail">
          <div className="caption">
            <h3>
              <i className="fa fa-fw fa-video-camera"></i> Videos{" "}
              <small>
                <i className="fa fa-hourglass"></i> Waiting for videos...
              </small>
            </h3>
          </div>
        </div>
      );
    }

    var videosLength = videos.length;
    var paginateLeftActive = activeVideoIndex > 0;
    var paginateRightActive =
      videosLength > 1 && activeVideoIndex != videosLength - 1;
    // var time = moment(activeVideo.createdAt).format("LLL");var time
    var time = this.props.convertToUtcOffset(this.props.incident.updatedAt, this.props.incident.timezone_utc_offset, "LLL")
    // convertToUtcOffset(incident.updatedAt, incident.timezone_utc_offset, "LLL")

    return (
      <Card>
        <Card.Body>
          {activeVideo.image ? (
            <div className="embed-responsive">
              <img src={activeVideo.url} className="user-img" />
            </div>
          ) : (
            <div className="embed-responsive embed-responsive-4by3" key={activeVideo.id as string}>
              <video preload="auto" ref="video" controls autoPlay loop>
                <source src={activeVideo.url} type="video/mp4" />
              </video>
            </div>
          )}

          <div className="row mt-1 mb-1">
            <div className="col">
              <i className="fa fa-clock-o"></i> {time}
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <button
                type="button"
                className={
                  "btn btn-block btn-play " +
                  (!paginateLeftActive ? "btn-primary" : "btn-success")
                }
                onClick={this.handlePageChange.bind(this, "prev")}
              >
                <i className="fa fa-chevron-circle-left fa-fw"></i> Prev
              </button>
            </div>
            <div className="col-2 text-center">
              {activeVideoIndex + 1} of {videos.length}
            </div>
            <div className="col-5">
              <button
                type="button"
                className={
                  "btn btn-block btn-play " +
                  (!paginateRightActive ? "btn-primary" : "btn-success")
                }
                onClick={this.handlePageChange.bind(this, "next")}
              >
                Next <i className="fa fa-chevron-circle-right fa-fw"></i>
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }

  componentDidMount() {
    ppicApiService.getUsersRelatedToLocation(`${this.props.incident.id}`).then((res:any) => {
      console.log("res---->", res)
      this.setState({users: res})
    })
    // ppicApiService.getUsersRelatedToLocation("5697017").then((res:any) => {
    //   console.log("res---->", res)
    //   this.setState({users: res})
    // })
  }

  renderMap() {
    var { activeVideoIndex } = this.state;
    const { locations, incident, videos } = this.props;
    const activeVideo = videos[activeVideoIndex]
    var markers: VideoMarker[] = [];

    const initialPosition: VideoMarker = {
      title: "Initial Position",
      // icon: this.deviceMarkerIcons.default,
      zIndex: 999,
      position: {
        lat: incident.location.coordinates[1],
        lng: incident.location.coordinates[0],
      },
    };

    if (videos.length > 0) {
      markers = videos
        .filter((v) => v.location)
        .map((video, index) => {
          var icon = this.deviceMarkerIcons.default;
          if (activeVideoIndex == index) {
            icon = this.deviceMarkerIcons.active;
          } else if (index == 0) {
            icon = this.deviceMarkerIcons.latest;
          }

          return {
            title: "#" + (index + 1),
            icon: icon,
            onClick: this.handleMarkerClick.bind(this, index),
            zIndex: 9000 - index,
            position: {
              lat: video.location.coordinates[1],
              lng: video.location.coordinates[0],
            },
          };
        });
        
        if (this.props.incident.is_geo_creds) {
          this.state.users.map((user:any) => {
            let a: VideoMarker = {
              title: `${user.username} ${user.mobile}}`,
              zIndex: 9999,
              position: {
                lat: Number(user.latitude),
                lng: Number(user.longitude)
              },
              onClick: function(){},
              isUser: true
            }
            markers.push(a)
          })
        }
    }

    return (
      <IncidentMap
        video={activeVideo}
        markers={markers}
        initialPosition={initialPosition}
        locations={locations}
      />
    );
  }

  render() {
    const { activeVideoIndex } = this.state;
    const activeVideo = this.props.videos[activeVideoIndex]
    const address = activeVideo ? activeVideo.address : "";
    return (
      <div className="row">
        <div className="col-sm-12 col-lg-6">{this.renderVideo()}</div>
        <div className="col-sm-12 col-lg-6">
          <Card>
            <Card.Body>
              {this.renderMap()}
              <ul
                className="list-inline"
                style={{ paddingTop: "1rem", fontSize: "13px" }}
              >
                <li className="list-inline-item">
                  <img
                    src={this.deviceMarkerIcons.latest}
                    alt=""
                    style={{ height: "24px", width: "24px" }}
                  />{" "}
                  Most Recent Video &nbsp;{" "}
                </li>
                <li className="list-inline-item">
                  <img
                    src={this.deviceMarkerIcons.active}
                    alt=""
                    style={{ height: "24px", width: "24px" }}
                  />{" "}
                  Active Video
                </li>
                <li className="list-inline-item">
                  <img
                    src={this.deviceMarkerIcons.default}
                    alt=""
                    style={{ height: "24px", width: "24px" }}
                  />{" "}
                  Past Video
                </li>
              </ul>

              <div>
                <h5>
                  <i className="fa fa-map-marker"></i> {address}
                </h5>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}
