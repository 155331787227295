export default {
	appVersion: process.env["REACT_APP_VERSION"],
	codeMaxLength: 6,
	domain: process.env["REACT_APP_DOMAIN"],
	ga: process.env["REACT_APP_GA"],
	googleClientKey: process.env["REACT_APP_GOOGLE_CLIENT_KEY"],
	installUrl: process.env["REACT_APP_INSTALL_URL"],
	ppApi: {
		url: process.env["REACT_APP_PP_API_URL"]
	},
	ppicApi: {
		url: process.env["REACT_APP_API_URL"],
		securityCode: process.env["REACT_APP_API_SECURITY_CODE"]
	},
}