import moment from "moment";
import {
  Incident as IIncident,
  IncidentVideo,
  AppUser,
} from "incident-code-core";
import React from "react";
import { Card } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import _ from "underscore";
import { ppicApiService } from "../../services/ppic-api-service";
import Download from "./download";
import VideoContainer from "./video-container";
import { UserIcon } from "../UserIcon";
import blankPerson from "../../assets/img/blank_person.png";

interface State {
  incident: IIncident;
  videos: IncidentVideo[];
  locations: [];
  interval: any;
  chatHistory: [];
}

export default class Incident extends React.Component<
  RouteComponentProps,
  State
> {
  incidentId: string;

  constructor(props: RouteComponentProps) {
    super(props);
    this.incidentId = (props.match.params as any).id;

    this.state = {
      incident: null,
      videos: [],
      locations: [],
      interval: null,
      chatHistory: []
    };
  }

  componentDidMount() {
    ppicApiService.init().then(() => {
      if (this.props.location.state) {
        this.setState(
          {
            incident: this.props.location.state,
          },
          () => {
            // clear cache
            history.replaceState(null, "");
            this.fetchIncident();
          }
        );
      } else {
        this.fetchIncident();
      }
    });
  }
  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    var { incident, videos, locations, chatHistory } = this.state;

    // if the incident data is not loaded yet, show a loading message
    if (!incident) {
      return (
        <div className="column row text-center">
          <br />
          <div className="callout success">
            <i className="fa fa-spinner fa-spin"></i>{" "}
            <strong>Fetching incident ...</strong>
          </div>
        </div>
      );
    }

    // Get rid of all characters from the teltag link
    var user = incident.createdBy
      ? (incident.createdBy as AppUser)
      : { firstName: "unknown", lastName: "unknown", phone: "unknown" };

    var username = `${user.firstName} ${user.lastName}`;
    var comment = incident.comment || "No Comment";

    return (
      <div>
        <VideoContainer
          videos={videos}
          incident={incident}
          locations={locations}
        />

        <Card className="mt-4 mb-4">
          <Card.Header>
            <i className="fa fa-fw fa-comment"></i> Comment
          </Card.Header>
          <Card.Body>
            {chatHistory.map((v : any, i: number) => {
              if (v.type === "comment") {
                return (
                  <div className="row" key={i}>
                    <div className="col-xs-12">
                      <p>
                        <strong>
                          {moment(v.createdAt).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )}
                          :{" "}
                        </strong>
                        {v.content}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
          </Card.Body>
        </Card>

        <Card className="mt-4 mb-4">
          <Card.Header>
            <i className="fa fa-fw fa-user"></i> Reporter Info
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="col-md-auto">
                <UserIcon user={user} />
              </div>

              <div className="col-md-auto">
                <div>
                  <a href={"tel:" + user.phone} className="btn btn-primary">
                    <i className="fa fa-phone"></i> Call {username}
                  </a>
                </div>
                <div>
                  Phone Number: <a href={"tel:" + user.phone}>{user.phone}</a>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>

        <Download incident={incident} videos={videos} />
      </div>
    );
  }

  async fetchIncident() {
    try {
      const [incident, { locations }, videoResp, chatHistory] = await Promise.all([
        ppicApiService.getIncident(this.incidentId),
        ppicApiService.getIncidentLocations(this.incidentId),
        ppicApiService.getIncidentVideos(this.incidentId),
        ppicApiService.getChatHistory(this.incidentId)
      ]);
      const user = incident.createdBy as AppUser;
      const videos =  [
            { url: user.portraitUrl || blankPerson, image: true } as IncidentVideo,
            ..._.chain(videoResp.data)
              .sortBy("createdAt")
              .sortBy("id")
              .value()
              .reverse(),
          ]

      if (incident.isResolved && !!this.state.interval) {
        clearInterval(this.state.interval);
      } else if (!incident.isResolved && !this.state.interval) {
        const interval = setInterval(() => {
          this.fetchIncident();
        }, 5000);
        this.setState({
          incident,
          locations: locations ? locations : [],
          videos,
          chatHistory: chatHistory && chatHistory.data ? chatHistory.data.history : [],
          interval,
        });
      } else {
        this.setState((prevState) => {
          return {
            ...prevState,
            incident,
            locations: locations ? locations : [],
            videos,
            chatHistory: chatHistory && chatHistory.data ? chatHistory.data.history : [],
          };
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
}
